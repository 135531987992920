.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  max-width: 500px;
  padding: 40px;
  border-radius: 8px;
}
