.seeder {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.qr_code img {
  display: block;
  margin: 0 auto;
}

.qr_code span {
  border: 1px solid black;
  border-radius: 1rem;
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
  overflow-wrap: break-word;
  padding: 1rem;
}

.btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .btn {
    width: 50%;
  }
}
