.items {
  align-items: center;
  display: grid;
  font-size: 1rem;
  gap: 1rem;
  grid-template-columns: min-content min-content auto min-content;
  line-height: 1.25rem;
  margin: 2rem 0;
  white-space: nowrap;
}
